<template>
  <div>
    <HeaderBasic>
      <h2 class="page-title">What we do</h2>
      <router-link to="/" :exact="true">Home</router-link>
      <span> / </span>
      <span class="current">What we do</span>
    </HeaderBasic>
    <!-- Start features Section -->
    <div class="features section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-6">
            <div class="wow animate__animated animate__fadeInLeft">
              <h2 class="section-title" style="font-size: 30px;">What we do</h2>
              <p class="desc">
                We will look after the whole process for you, so you don't have
                the stress of looking after your property.
              </p>
              <div class="row">
                <div class="col-md-6">
                  <div class="features-box">
                    <div class="features-content">
                      <h4><i class="icon-home"></i> Letting and Selling</h4>
                      <p>
                        We let/sell and manage your property for you honestly
                        and transparently.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="features-box">
                    <div class="features-content">
                      <h4><i class="icon-briefcase"></i> Rent Collection</h4>
                      <p>
                        We do the rent collection and management so you don't
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="features-box">
                    <div class="features-content">
                      <h4>
                        <i class="icon-settings"></i> Maintenance and Management
                      </h4>
                      <p>
                        We do the maintenance and management of the property
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="features-box">
                    <div class="features-content">
                      <h4><i class="icon-notebook"></i> Inspection</h4>
                      <p>
                        We organise and manage property inspections for you
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="features-box">
                    <div class="features-content">
                      <h4><i class="icon-lock"></i> Deposits Registered</h4>
                      <p>
                        We register your deposit
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="row">
              <div class="thum-ft wow animate__animated animate__fadeInRight">
                <img src="assets/img/featured/img1.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End features Section -->
    <!-- Start About Section  -->
    <section class="about section custom-about">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h3>Residential Sales</h3>
            <p>
              We offer a complete property sales service for all of our clients
              and regularly receive very positive client feedback. Our friendly
              team of professional staff are always on hand to answer any
              questions you may have.
            </p>
            <p>
              Our team has a true understanding of property which is backed up
              with excellent local knowledge and of the industry as a whole.
              This is along with our excellent people skills and negotiating
              skills, which are advocated by the hands-on directors, meaning you
              get the type of service that you want.
            </p>
            <p>
              Whether you are looking to buy or are thinking of selling your
              property, our tried and tested sales service ensures that your
              house or flat will be marketed effectively to the right audience.
            </p>
            <p>
              At Asamai Properties, we offer varied residential properties for
              sale and ensure that our customers are provided with the utmost
              excellent service. The property we sell are designed and have been
              developed with you in mind or zoned for living such as family
              houses, apartments.
            </p>
            <p>
              At Asamai Properties, we tend to have or arrange any of the
              following properties that are available for occupation. Our
              inventory of properties can include the following residential
              property.
            </p>
          </div>
          <div class="col-md-6">
            <h3>What works for you?</h3>
            <p>
              Quite simply; we get it. As a landlord, you want to rent your
              property out as quickly as possible for the best price. You want
              to avoid empty periods and guarantee a regular income.
            </p>
            <p>
              At Asamai properties, if you don't want to be bothered with the
              day-to-day management of the property, we'll sort all that out too
              and all you will need is to see the agreed payments into your
              chosen bank.
            </p>
            <p>
              Letting property has been a core element of our business since we
              opened our doors for business – we understand the complexities.
            </p>
            <p>
              At Asamai Properties, every landlord and every property is
              different, so we will tailor our proposal to you. You want to be
              kept in the loop at every stage? We hear you. Prefer to leave it
              all to us while you get on with other things.
            </p>
            <ul>
              <li>
                <b>Condominiums:</b> privately owned units within larger
                buildings or communities, similar to the structure of apartment
                buildings.
              </li>
              <li>
                <b>Townhomes:</b> units that are usually larger than condos but
                still share walls with one or two other buildings.
              </li>
              <li>
                <b>Cooperatives:</b> units within one building where everyone
                living in the building owns the building together.
              </li>
              <li>
                <b>Single-family houses:</b> usually built on a single lot
                without having to share space with other dwellings.
              </li>
              <li>
                <b>Multi-family houses:</b> usually range in size from two to
                four units, such as a duplex or a four-plex, but anything larger
                than four units is considered commercial property.
              </li>
            </ul>
          </div>
          <div class="col-md-6 wow animate__animated animate__fadeInLeft">
            <h3>Commercial Lettings</h3>
            <p>
              The need for the accommodation to be let on a commercial basis is
              important, but does not always appear to be appreciated,
              particularly by unrepresented taxpayers.
            </p>
            <p>
              A loss in the first year of letting a property is quite usual
              (because of capital investment costs and lower occupancy and
              tariff rates) and it may be that losses occur in the second and
              possibly third year of a letting. However, if losses are incurred
              for more than three consecutive years, then it is more challenging
              to see how the letting is going to be justified as being
              commercial (although HMRC have tended to allow a longer period).
              Lettings to family and friends at reduced rates are not
              commercial. It is also likely to be harder to meet this test if
              there are very high borrowings on a property and if staff are
              employed to deal with all the cleaning and changeovers etc.
            </p>
            <p>
              At Asamai Properties, we look for commercial properties through
              our arranged supply chain if we don't have the property you are
              looking for a fee. This makes us unique in the market and will
              always support you through the process until when you receive your
              keys to the let commercial property.
            </p>
            <p>
              At Asamai Properties, our pride is our desire to satisfy our
              customers by taking the extra mile for continued happiness with
              your let property.
            </p>
            <p>
              At Asamai Properties, we have our sister company, Asamai
              Consulting Ltd, a firm of Accountants and Business Advisors, who
              are happy to support with your financial services to ensure that
              you know what are paying for as corporation tax and ensure that
              are not disadvantaged.
            </p>
          </div>
          <div class="col-md-6 wow animate__animated animate__fadeInRight">
            <h3>EPC's and Gas Certificates</h3>
            <p>
              As a Landlord, did you know you require an EPC for every new
              property on the market?
            </p>
            <h3>Energy Performance Certificates</h3>
            <p>
              Energy Performance Certificates (EPCs) were introduced in England
              and Wales on 1 August 2007 as part of Home Information Packs
              (HIPs) for domestic properties with four or more bedrooms.
            </p>
            <p>
              Over time this requirement was extended to smaller properties.
              When the requirement for HIPs was removed in May 2010, the
              requirement for EPCs continued.
            </p>
            <p>
              Energy Performance Certificates (EPCs) are needed whenever a
              property is: Built, Sold or Rented.
            </p>
            <p>
              If you're selling or renting your home, you must order an EPC for
              potential buyers and tenants before you market your property.
            </p>
            <p>
              If you're selling or renting your home, you must order an EPC for
              potential buyers and tenants before you market your property.
            </p>
            <p>
              At Asamai Properties, we can prepare your EPC FOR ONLY £100 and
              make all the necessary arrangement with our supply chain to get
              you the best services that you need. If you don't get an EPC when
              you need one, you can be fined.
            </p>
          </div>
          <div class="col-md-6 wow animate__animated animate__fadeInRight">
            <h3>Property Management</h3>
            <p>
              Property management is the overseeing of residential, commercial
              and/or industrial real estate, including apartments, detached
              houses, condominium units, and shopping centres.
            </p>
            <p>
              It typically involves the managing of property that is owned by
              another party or entity. The property manager, Asamai Properties,
              acts on behalf of the owner to preserve the value of the property
              while generating income.
            </p>
            <p>
              At Asamai Properties, we offer these services to all types of
              customers for a few.
            </p>
          </div>
          <div class="col-md-6 wow animate__animated animate__fadeInLeft">
            <div class="featured-thumb">
              <img src="/assets/img/about/img2.jpg" alt="" />
            </div>
          </div>
          <div class="col-md-6 wow animate__animated animate__fadeInRight">
            <h3>
              Reasons for Hiring Property Management Asamai Property Firm.
            </h3>
            <p>
              Property owners hire property management firms for various
              reasons. Some owners may have many rental properties in their
              portfolios but lack the time or expertise to maintain the
              properties and deal with tenants. Some owners only have an
              interest in owning rental properties and earning profits from
              them. When this is the case, they hire professional property
              managers. Absentee landlords also make use of property management
              services.
            </p>
            <p>
              At Asamai Properties, we keep our property owners who participate
              in affordable housing programs sometimes make use of property
              management services informed all the way through. In other words,
              we at Asamai properties are available and can be hired to
              management all your property portfolios for a reasonable little
              fee.
            </p>
            <p>
              This is because participating in such programs requires knowledge
              of federal guidelines that some owners do not have, even though
              they wish to reap the benefits of affordable housing programmes.
            </p>
            <p>
              At Asamai properties, you are always the winner as our client and
              all our contractual engagements are always transparent and aim to
              keep the customer always happy.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Section  -->
  </div>
</template>
<script>
import { WOW } from "wowjs";
export default {
  components: {
    HeaderBasic: () => import("./HeaderBasic")
  },
  mounted() {
    var wow = new WOW({
      live: false
    });
    wow.init();
  }
};
</script>
